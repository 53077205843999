/*- - - - - - - - FONTS - - - - - - -*/

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/Montserrat-Regular.woff') format('woff'),
    url('../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/Montserrat-Bold.woff') format('woff'),
    url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Black';
  src: url('../fonts/Montserrat-Black.woff') format('woff'),
    url('../fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: black;
  font-style: normal;
  font-display: swap;
}

.primary-div {
  background-color: #8c3d97 !important;
}

.diluted-primary-div {
  background: rgba(129, 188, 104, 0.15) !important;
}

.secondary {
  color: #a18ca3 !important;
}

.secondary-div {
  background-color: #eee2ef !important;
}

.diluted-secondary-div {
  background: rgba(242, 159, 5, 0.15) !important;
}

/* - - - - - - Forms - - - - - - - */

.form-title {
  text-align: center;
  font-size: 40px;
}

.form {
  display: flex;
  align-items: center;
  background-color: #eed9f2;
  height: 100vh;
  width: 100%;
}

.form-container {
  position: relative;
  background-color: #f6eaf8;
  -webkit-box-shadow: 0 0 4px black;
  box-shadow: 0 0 4px black;
  display: flex;
  height: fit-content;
  justify-content: center;
  padding-bottom: 40px;

  align-items: center;
  width: 500px !important;
}

#form-lang-selector {
  position: absolute;
  right: 0;
  padding: 10px;
}

#form-lang-selector #language-dropdown,
#form-lang-selector .right-adornment {
  color: black;
}

.form-item {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.form-label {
  justify-content: flex-start;
}

/* - - - - - - Home Page - - - - - - - */

/* 修改 placeholder 文本的字体大小 */
input::placeholder,
textarea::placeholder {
  font-family: 'Montserrat', sans-serif;
}

#homepage {
  overflow: hidden;
  margin: 0px;
  font-family: 'Montserrat', sans-serif;
  background-image: url('../images/main_background.png');
  background-size: cover;
}

#main-container {
  display: flex;
}

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-wrap: "wrap";
  background-image: url('../images/login_background.png');
  background-size: cover;
}

.login-logo {
  width: 25vh;
  margin: 40px;
}

.error_title {
  font-family: "Montserrat-Black";
  font-size: 26px;
  font-weight: black;
  /* text-transform: uppercase; */
  color: #501662;
  margin: 10px;
  text-align: center;
}

.error_text {
  font-family: "Montserrat-Black";
  font-size: 20px;
  font-weight: black;
  color: #501662;
  margin: 10px;
  text-align: center;
}

.error_img {
  width: 35vh;
  margin: 40px;
}

.paper-with-shadow {
  padding: 8px 15px 8px 15px;
  width: 45vh;
  border-radius: 8px;
  margin: 10px;
  box-shadow: 0px 0px 10px !important;
}

.login-input {
  width: 100%;
}

#sidebar-container {
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  /* 4 rows for profile, searchbar, session list and conversation */
  height: 100vh;
  width: 350px;
  background: linear-gradient(#Af4687, #E6B96A);
}

#sidebar-profile-box {
  display: flex;
  flex-direction: column;
  position: relative;
  color: white;
  padding: 10px;
}

#sidebar-searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  padding: 10px;
  width: 90%;
  justify-self: center;
}

#sidebar-session-list {
  display: flex;
  overflow-y: auto;
  color: white;
  border-radius: 7px;
  background: #eee2ef;
  flex-direction: column;
  width: 90%;
  height: 90%;
  justify-self: center;
  margin-top: 20px;
  overflow-x: hidden;
}

#sidebar-inperson-button {
  display: flex;
  padding: 10px;
  padding-bottom: 30px;
  height: 50px;
  justify-content: center;
  border-radius: 10px;
}

.add_contact_img {
  width: 38px;
  margin: 38px;
}

#message-panel-container {
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr auto;
  /* 3 rows for header, messages and inputBar */
  height: 100vh;
  width: 100%;
  background-image: url('../images/message_panel_background.png');
  background-size: cover;
}

#message-panel-header {
  padding: 10px;
  background-color: rgba(224, 203, 226, 0.5);
  /* #e0cbe2 的透明度为 50% */
  color: #513695;
  font-weight: bold;
  align-items: center;
}

#message-panel-messages {
  overflow-y: auto;
  padding: 10px;
}

#message-panel-input-bar {
  padding: 20px;
}

.language-selector {
  margin: 5px;
}

#profile-avatar {
  position: relative;
  font-size: 30px;
  width: 70px !important;
  height: 70px !important;
  right: 0;
}

.profile-box-logo {
  width: 11vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

#profile-avatar:hover {
  filter: brightness(0.95);
}

.searchbar {
  background-color: white;
  width: 100%;
  border-radius: 7px;
  align-self: center;
  font-size: '16px';
}

.dialog-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #A24D84;
  border: 1px;
  border-style: solid;
  border-radius: 7px;
  padding: 5px;
  width: 95%;
  height: 30px;
  justify-self: center;
}

.dialog-input-admin {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #A24D84;
  border: 1px;
  border-style: solid;
  border-radius: 7px;
  padding: 5px;
  width: 78%;
  height: 30px;
  justify-self: center;
}

.dialog-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#user-list {
  display: flex;
  overflow-y: auto;
  color: white;
  border-radius: 7px;
  background: #f8f5f8;
  flex-direction: column;
  width: 45vh;
  height: 40vh;
  justify-self: center;
  margin-top: 20px;
  margin-bottom: 35px;
  overflow-x: hidden;
}

.user-item {
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  color: black;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  height: 50px;
  border-bottom: solid 1px #e0cbe2;
}

.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
}

#group-member-list {
  display: flex;
  overflow-y: auto;
  color: white;
  border-radius: 7px;
  background: #f8f5f8;
  flex-direction: column;
  width: 45vh;
  height: 40vh;
  justify-self: center;
  margin-top: 20px;
  margin-bottom: 35px;
  overflow-x: hidden;
}

.group-member-item {
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  color: black;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  height: 50px;
  border-bottom: solid 1px #e0cbe2;
}

.group-member-item:hover {
  background-color: #501662;
  color: white;
}

.group-member-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
}

#group-status-list {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  width: 45vh;
  justify-self: center;
  overflow-x: hidden;
}

.group-status-item {
  padding: 0px;
  display: flex;
  color: black;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.group-status-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.menu-icon {
  padding-right: 6px;
}

#customized-dialog-title {
  background-color: #Af4687;
}

.file-message {
  background-color: white;
  border: 2px solid #f1eded;
  display: flex;
  margin: 10px;
  padding: 10px;
}

.file-details {
  flex-grow: 1;
}

.file-icon {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.bubble-msg-up {
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 8px;
  margin-bottom: 5px;
}

.bubble-msg-down {
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 5px;
  margin-bottom: 8px;
}

.direction-rtl {
  direction: rtl;
}

.error-message {
  color: red !important;
  font-size: 1rem;
  text-align: center;
  width: 100%;
}

.site {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  --swiper-theme-color: white !important;

  font-family: 'Montserrat', sans-serif;
  white-space: pre-line;
}

.clickable {
  cursor: pointer;
}

/*   Scrollbar    */
.scrollable-y {
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #513695;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5136b1;
  border-radius: 0px;
}

.no-conversations {
  display: flex;
  margin-top: 3vh;
  color: white;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.conversation {
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  color: black;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  height: 65px;
  border-bottom: solid 1px #e0cbe2;
}

.conversation:hover {
  background-color: #501662;
  color: white;
}

.selected {
  background-color: #501662;
  color: white;
}

.avatar {
  width: 50px !important;
  height: 50px !important;
  margin: 0vh;
  margin-left: 2vh;
}

.avatar.bigger {
  width: 45px !important;
  height: 45px !important;
  margin: 1vh;
}

.avatar.small {
  width: 45px !important;
  height: 45px !important;
  margin: 1vh;
}

.conversation-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.conversation-status {
  width: 100%;
  text-align: left;
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: 0px;
  color: #b4b6b4;
}

.message-date {
  color: #571066;
  padding: 0px 5px;
  width: auto;
  white-space: nowrap;
  align-self: center;
  font-weight: bold;
}

.divider {
  /* minor cosmetics */
  display: table;
  font-size: 15px;
  text-align: center;
  width: 85%;
  /* divider width */
  margin: 2px auto;
  /* spacing above/below */
}

.divider span {
  display: table-cell;
  position: relative;
}

.divider span:first-child,
.divider span:last-child {
  width: 50%;
  top: 13px;
  /* adjust vertical align */
  -moz-background-size: 100% 2px;
  /* line width */
  background-size: 100% 2px;
  /* line width */
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}

.divider span:first-child {
  /* color changes in here */
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(#8c3d97));
  background-image: -webkit-linear-gradient(180deg, transparent, #8c3d97);
  background-image: -moz-linear-gradient(180deg, transparent, #8c3d97);
  background-image: -o-linear-gradient(180deg, transparent, #8c3d97);
  background-image: linear-gradient(90deg, transparent, #8c3d97);
}

.divider span:nth-child(2) {
  color: #8c3d97;
  padding: 0px 5px;
  width: auto;
  white-space: nowrap;
}

.divider span:last-child {
  /* color changes in here */
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#8c3d97), to(transparent));
  background-image: -webkit-linear-gradient(180deg, #8c3d97, transparent);
  background-image: -moz-linear-gradient(180deg, #8c3d97, transparent);
  background-image: -o-linear-gradient(180deg, #8c3d97, transparent);
  background-image: linear-gradient(90deg, #8c3d97, transparent);
}

.input-msg-bar {
  background-color: white;
  width: 100%;
  border-radius: 45px;
  align-self: center;
  font-size: '16px';
}

/* Message Send Button*/

#chat button:enabled {
  background-color: #9b19b3;
}

#chat button:hover {
  filter: brightness(0.9);
}

/* Message Input */

#chat .Mui-focused fieldset {
  border-color: #9b19b3 !important;
}

#no-chat-user {
  height: 100vh;
  width: 100%;
  font-weight: bold;
  color: #513695;
  background-color: #eee2ef;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

#language-dropdown-white {
  color: white;
  text-transform: none;
}

#language-dropdown-purple {
  color: #501662;
  text-transform: none;
}

.right-adornment-white {
  color: white;
}

.right-adornment-purple {
  color: #501662;
}

/* .MuiPaper-rounded {
    border-radius: 20px !important;
  } */